import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProjectsRoutingModule } from './projects-routing.module';
import { ProjectsComponent } from './projects.component';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { FormsModule } from '@angular/forms';
import { WindowlistModule } from 'projects/core/src/common/windowlist/windowlist.module';
import { WindowModule } from 'projects/core/src/common/window/window.module';
import { ToolbarmodelModule } from 'projects/core/src/common/toolbarmodel/toolbarmodel.module';
import { PaginationModule } from 'projects/core/src/common/pagination/pagination.module';
import { SelectfieldcustomModule } from 'projects/core/src/common/selectfieldcustom/selectfieldcustom.module';
import { AddressModule } from '../address/address.module';
import { DocumentsModule } from '../documents/documents.module';
import { FilterdateModule } from '../../modules/filterdate/filterdate.module';
import { FilesModule } from '../../modules/files/files.module';


@NgModule({
  declarations: [
    ProjectsComponent,
    ProjectDetailComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ProjectsRoutingModule,
    WindowlistModule,
    WindowModule,
    ToolbarmodelModule,
    PaginationModule,
    SelectfieldcustomModule,
    AddressModule,
    DocumentsModule,
    FilterdateModule,
    FilesModule
  ]
})
export class ProjectsModule { }
