import { NgModule } from '@angular/core';
import { StripeComponent } from './stripe.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxStripeModule, StripeService } from 'ngx-stripe';

@NgModule({
    imports: [FormsModule,
              ReactiveFormsModule,
              CommonModule, 
              NgxStripeModule.forRoot()],
   exports: [StripeComponent],
   declarations: [StripeComponent],
   providers: [StripeService],
})
export class StripeModule{ }